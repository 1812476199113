import { SnackBarService } from './../../utils/snack-bar.service';
import { EmisorEventoGeralService } from './../../utils/emisorEventoGeral.service';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ImagemRenderizada } from '../../interface/imagenRenderizada';

@Component({
  selector: 'app-view-imagens-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './view-imagens-modal.component.html',
  styleUrls: ['./view-imagens-modal.component.scss']
})
export class ViewImagensModalComponent implements OnInit {

  @Input() linkImagem: ImagemRenderizada = { nomeArquivo: '', path: '' };
  @Output() fecharModal = new EventEmitter<Boolean>();
  valorZoom: number = 1;

  constructor(
    private emisorEventoGeralService: EmisorEventoGeralService,
    private snackBarService:SnackBarService,
  ) { }

  ngOnInit() {

  }

  public fechar() {
    this.emisorEventoGeralService.fecharModal.emit(true);
    this.valorZoom = 1;
  }

  public pegarPosicao(evento: MouseEvent) {
    const imagem = document.querySelector(".imagem") as HTMLElement;
    const containerImagem = document.querySelector(".contaienr-imagem") as HTMLElement;

    let posicaoX = evento.clientX - containerImagem.offsetLeft;
    let posicaoY = evento.clientY - containerImagem.offsetTop;
    
    const mWidth = containerImagem.offsetWidth;
    const mHeight = containerImagem.offsetHeight;

    posicaoX = posicaoX / mWidth * 100;
    posicaoY = posicaoY / mHeight * 100;

    imagem.style.transform = `translate(-${posicaoX}%,-${posicaoY}%) scale(${this.valorZoom})`;

  }

  public resetZoom(evento: MouseEvent) {
    const imagem = document.querySelector(".imagem") as HTMLElement;
    imagem.style.transform = `translate(-50%,-50%) scale(1)`;
  }

  public aumentarZoom() {
    if(this.valorZoom < 5){
    this.valorZoom++;
    }else{
      this.snackBarService.openSnackBar("Valor maximo atingido","my-snack-bar-warning",2000)
    }
  }
  public diminuirZoom() {
    if(this.valorZoom > 1){
      this.valorZoom--;
    }else{
      this.snackBarService.openSnackBar("Valor minimo atingido","my-snack-bar-warning",2000)
    }
   
  }

}
