<div class="modal fade" id="modalImagem" tabindex="1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-fullscreen">
    @if(linkImagem){
    <div class="modal-content">
      <div class="col-12 d-flex justify-content-end">
        <button type="button" class="btn btn-sm" data-bs-dismiss="modal" aria-label="Close" (click)="fechar()"><i
            class="fa-solid fa-xmark"></i></button>
      </div>
      <div class="modal-body">
        <figure class="contaienr-imagem" (mousemove)="pegarPosicao($event)" (mouseleave)="resetZoom($event)">
          <img [src]="linkImagem.path" class="imagem" alt="{{linkImagem.nomeArquivo}}">
        </figure>
      </div>
      <div class="row p-3 d-flex justify-content-center">
        <div class="col-1 d-flex justify-content-center">
          <i class="fa-solid fa-magnifying-glass-minus" (click)="diminuirZoom()"></i>
        </div>
        <div class="col-3 d-flex justify-content-center">
          <span><b>Zoom: {{valorZoom}}</b></span>
        </div>
        <div class="col-1  d-flex justify-content-center">
          <i class="fa-solid fa-magnifying-glass-plus" (click)="aumentarZoom()"></i>
        </div>
      </div>
    </div>
    }
  </div>
</div>